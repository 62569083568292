<template>
  <v-container v-if="vueAddProduto">
    <v-row>
      <v-col cols="12">
        <PageToolbar
          title="Adicionar Produto"
          icon="shopping_cart"
          voltar
          dark
        />
      </v-col>

      <v-col cols="12">
        <v-card class="pa-6">
          <v-row>
            <!-- Descrição -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.descricao"
                label="Descrição"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- Classificações -->
            <v-col cols="12" md="4">
              <v-select
                v-model="produto.produto_classificacao_id"
                :items="classificacoes"
                :loading="loadingClass"
                clearable
                item-text="descricao"
                item-value="id"
                dense
                filled
                hide-details
                label="Classificações"
              ></v-select>
            </v-col>

            <!-- Referencia -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.referencia"
                label="Referencia"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- Fornecedor -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.fornecedor"
                label="Fornecedor"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- Costo -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.costo"
                label="Costo"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- Precio -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.precio"
                label="Precio"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- Url -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.url"
                label="Url"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- Estoque Min -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.estoque_min"
                label="Estoque Min"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- Estoque Max -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.estoque_max"
                label="Estoque Max"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-card
                flat
                style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                height="56px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-subheader
                  style="height: auto !important"
                  class="ma-0 pa-0 body-1"
                >
                  Exige Serial
                </v-subheader>

                <v-switch
                  class="ma-0 pa-0"
                  hide-details
                  :false-value="false"
                  :true-value="true"
                  v-model="produto.exige_serial"
                ></v-switch>
              </v-card>
            </v-col>

            <!-- Qtde por Caixa -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="produto.qtde_caixa"
                label="Quantidade por caixa"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <!-- Unidade de Medida -->
            <v-col cols="12" md="4">
              <v-select
                v-model="produto.und"
                :items="unidades"
                clearable
                item-text="text"
                item-value="value"
                dense
                filled
                hide-details
                label="Unidade de Medida"
              ></v-select>
            </v-col>

            <!-- Descrição detalhada -->
            <v-col cols="12">
              <v-textarea
                v-model="produto.descricao_detalhada"
                label="Descrição detalhada"
                :rows="3"
                filled
                hide-details
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      @click="criarProduto"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { postProdutos, getAllProClass } from "@/services/api/produtos.api.js";
import { mapGetters } from "vuex";

export default {
  name: "NovoProduto",
  props: {},
  data() {
    return {
      produto: {},
      classificacoes: [],
      loadingClass: true,
      unidades: [
        {
          text: "Metros",
          value: "mt",
        },
        {
          text: "Unidades",
          value: "un",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueAddProduto() {
      return this.getAccess("vueProduto", "adicionar");
    },
  },
  methods: {
    getProClass() {
      getAllProClass()
        .then((response) => {
          this.classificacoes = response;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingClass = false;
        });
    },
    criarProduto() {
      this.$Progress.start();
      postProdutos(this.produto)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Produto criado com sucesso!");
            this.produto = {};
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.$Progress.fail();
        });
    },
  },
  created() {},
  mounted() {
    if (this.vueAddProduto) {
      this.getProClass();
    }
  },
};
</script>

<style lang="scss" scoped></style>
